/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.css
 * - /npm/bootstrap-datepicker@1.9.0/dist/css/bootstrap-datepicker.standalone.min.css
 * - /npm/swiper@7.2.0/swiper-bundle.css
 * - /npm/daterangepicker@3.1.0/daterangepicker.css
 * - /npm/select2@4.1.0-rc.0/dist/css/select2.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
